// extend the datepicker and "override" the offending function, passing in the long names.
$.extend($.datepicker, {
  __base__generateMonthYearHeader: $.datepicker._generateMonthYearHeader,

  _generateMonthYearHeader: function (
    inst,
    drawMonth,
    drawYear,
    minDate,
    maxDate,
    secondary,
    monthNames,
    monthNamesShort
  ) {
    return $.datepicker.__base__generateMonthYearHeader(
      inst,
      drawMonth,
      drawYear,
      minDate,
      maxDate,
      secondary,
      monthNames,
      monthNames
    );
  },
});

document.addEventListener("DOMContentLoaded", function () {
  //fadein effect
  let elementsArray = document.querySelectorAll(".fade-in");
  //console.log(elementsArray);
  window.addEventListener("scroll", fadeIn);
  function fadeIn() {
    for (var i = 0; i < elementsArray.length; i++) {
      var elem = elementsArray[i];
      var distInView =
        elem.getBoundingClientRect().top - window.innerHeight + 20;
      if (distInView < 0) {
        elem.classList.add("inView");
      } else {
        elem.classList.remove("inView");
      }
    }
  }
  fadeIn();
});
